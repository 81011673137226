import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import { faAngular, faCss3, faGithub, faHtml5, faJsSquare, faReact, faWordpress } from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'


const About =() => {

   

    return(
       <>
        <div className='container about-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters
                        strArray={['A','b','o','u','t',' ','M','e']}
                        idx={15}
                    />
                </h1>
                <p>As an ambitious web developer, I am seeking a challenging role in an established IT company that offers the opportunity to work with the latest technologies on diverse and exciting projects. </p>
                <p>I have a quiet confidence and natural curiosity that drives me to continuously improve my skills by tackling design problems one at a time. In addition to my passion for development, </p>
                <p>I am a sports fanatic, photography enthusiast, and tech-obsessed, and I pride myself on being a dedicated family person.</p>


            </div>
            <div className='stage-cube-cont'>
                <div className='cubespinner'>
                    <div className='face1'>
                        <FontAwesomeIcon icon={faWordpress} color="#DD0031"/>
                    </div>
                    <div className='face2'>
                        <FontAwesomeIcon icon={faReact} color="#F06529"/>
                    </div>
                    <div className='face3'>
                        <FontAwesomeIcon icon={faHtml5} color="#28A4D9"/>
                    </div>
                    <div className='face4'>
                        <FontAwesomeIcon icon={faCss3} color="#5ED4F4"/>
                    </div>
                    <div className='face5'>
                        <FontAwesomeIcon icon={faJsSquare} color="#EFD81D"/>
                    </div>
                    <div className='face6'>
                        <FontAwesomeIcon icon={faGithub} color="#EC4D28"/>
                    </div>

                </div>

            </div>

        </div>

        <Loader type="pacman"/>
       </>
    )
    
}


export default About 