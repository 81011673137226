import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters';
import { useRef } from 'react';
import emailjs from '@emailjs/browser'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';

const Contact =()=> {

    const refForm=useRef

    // const sendEmail = (e) => {
    //     e.preventDefault()
    
    //     emailjs
    //       .sendForm("service_zjhfz2d", "template_te3d9qa", refForm.current, "NIb0POIpCnt7MdpBb")
    //       .then(
    //         () => {
    //           alert('Message successfully sent!')
    //           window.location.reload(false)
    //         },
    //         () => {
    //           alert('Failed to send the message,  please try again')
    //         }
    //       )
    //   }


    return(
        <>
            <div className='container contact-page'>

                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters strArray={['C','o','n','t','a','c','t',' ',' M','e']}
                         idx={15}
                        />
                    </h1>
                    <p>
                    My primary focus is on freelance opportunities, particularly those that are ambitious or involve large projects. However,
                    I am open to other requests or inquiries, and you may reach me using the form provided below.
                    </p>

                    <div className='contact-form'>
                        {/* <form ref={refForm} onSubmit={sendEmail}> */}
                        <form>
                            <ul>
                                <li className='half'>
                                    <input placeholder="Name" type="text" name="name" required />
                                </li>
                                <li className='half'>
                                    <input placeholder="Email" type="email" name="email" required />
                                </li>
                                <li>
                                    <input
                                        placeholder="Subject"
                                        type="text"
                                        name="subject"
                                        required
                                    />
                                </li>
                                <li>
                                    <textarea
                                        placeholder="Message"
                                        name="message"
                                        required>                                            
                                    </textarea>
                                </li>
                                <li>
                                    <input type="submit" className="flat-button" value="SEND" />
                                </li>
                            </ul>
                        </form>
                    </div>

                </div>
                <div className='info-map'>
                    Nizam Erumbayil,
                    <br/>
                    United Arab Emirates,
                    <br/>
                    Dubai
                    <br/>
                    <span>nizampnml@gmail.com</span>

                </div>
                <div className='map-wrap'>
                    <MapContainer center={[25.209219, 55.277178]}zoom={13}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Marker position={[44.96366, 19.61045 ]}>
                           <Popup>Nizam Lives Here,:) </Popup> 
                        </Marker>
                    </MapContainer>

                </div>
            </div>
            <Loader type="pacman"/>
        
        </>
    )
}

export default Contact 